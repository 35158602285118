export default function CustomButtonConfirm({ text, handleOnClick }) {
    return (
        <div className="flex w-full py-1">
            <button
                type="button"
                className="bg-black text-white hover:bg-gray-800 w-full rounded font-normal text-lg h-12"
                onClick={handleOnClick}
                onKeyDown={handleOnClick}
            >
                {text}
            </button>
        </div>
    )
}