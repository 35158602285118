import { Link, useLocation } from 'react-router-dom';
import { useAuthStore } from '../../store/hooks/useAuthStore';

export default function Menu() {
    const location = useLocation();
    const { logout } = useAuthStore()
    
    const menuItems = [
        { name: 'Inicio', link: '/', icon: 'monitoring' },
        { name: 'Enfermeros', link: '/enfermeros', icon: 'groups' },
        { name: 'Clientes / Pacientes', link: '/clientes', icon: 'diversity_3' },
        { name: 'Instituciones', link: '/instituciones', icon: 'corporate_fare' },
        { name: 'Blog', link: '/blog', icon: 'rss_feed' },
        { name: 'Soporte técnico', link: '/soprte-tecnico', icon: 'support_agent' },
        { name: 'Banca', link: '/banca', icon: 'account_balance' },
    ];

    return (
        <>
            <div className="flex flex-col h-screen min-w-60 max-w-60 border border-gray-400">
                <div className="flex flex-col justify-center items-center w-full h-20">
                    <p>Logo</p>
                </div>
                <div className="flex flex-col">
                    {
                        menuItems.map((item, index) => {
                            return (
                                <Link to={item.link} key={index} className={
                                    `flex flex-row justify-between items-center px-4 py-2 hover:bg-o2o-gray-alt hover:underline hover:text-base ${location.pathname === item.link ? "bg-o2o-gray-alt" : ""}`
                                }>
                                    <div className="w-10">
                                        <span className="material-symbols-outlined">
                                            {item.icon}
                                        </span>
                                    </div>
                                    <div className="flex-1">{item.name}</div>
                                </Link>
                            )
                        })
                    }
                </div>
                <div className='flex flex-col justify-center items-center'>
                    <button className='w-9/12 bg-black text-white mx-auto h-7 rounded' onClick={logout}>
                        Cerrar sesión
                    </button>
                </div>
            </div>
        </>
    )
}