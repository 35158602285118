import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpen: false,
    message: ''
}

export const ToastSlice = createSlice({
    name: "toast",
    initialState,
    reducers: {
        setOpen: (state, {payload}) => {
            state.isOpen = true;
            state.message = payload;
        },
        setClose: (state) => {
            state.isOpen = false;
            state.message = ''
        }
    }
});

export const {
    setOpen,
    setClose,
} = ToastSlice.actions;