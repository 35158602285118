import React, { useEffect } from 'react'
import FirstTab from './FirstTab';

const TabsNurce = ({ item }) => {

    useEffect(() => {
        const tabs = window?.document?.getElementsByClassName('tab') || [];
        const contents = window?.document?.getElementsByClassName('tab-content') || [];
        Array.from(tabs).forEach(element => {
            element.addEventListener("click", ({ target: { id } }) => {
                const elementId = id?.split('item-label-')?.length > 1 ? id?.split('item-label-')[1] : null;

                Array.from(tabs).forEach(subElement => {
                    if (id === subElement.id) {
                        subElement.classList.add('tab-active');
                    } else {
                        subElement.classList.remove('tab-active');
                    }
                });

                Array.from(contents).forEach(elementContent => {
                    const contentId = elementContent?.id?.split('item-content-')?.length > 1 ? elementContent?.id?.split('item-content-')[1] : null;
                    if (elementId == contentId) {
                        elementContent.classList.add('tab-content-active')
                    } else {
                        elementContent.classList.remove('tab-content-active')
                    }
                });
            });
        });
    }, []);

    return (
        <div className='flex flex-col shadow-sm rounded'>
            <div className='flex flex-row space-x-2 w-full'>
                <p className='tab tab-active' id='item-label-1'>Información general</p>
                <p className='tab' id='item-label-2'>Estudios y acreditaciones</p>
                <p className='tab' id='item-label-3'>Trabajo en 0ne2One</p>
            </div>
            <div className='flex flex-col w-full'>
                <FirstTab item={item}/>
                <div className='tab-content' id="item-content-2">
                    contenido 2
                </div>
                <div className='tab-content' id="item-content-3">
                    contenido 3
                </div>
            </div>
        </div>
    )
}

export default TabsNurce