import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpen: false
}

export const LoadingSlice = createSlice({
    name: "loading",
    initialState,
    reducers: {
        setOpen: (state) => {
            state.isOpen = true;
        },
        setClose: (state) => {
            state.isOpen = false;
        }
    }
});

export const {
    setOpen,
    setClose,
} = LoadingSlice.actions;