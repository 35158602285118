import { useDispatch, useSelector } from "react-redux";
import { setOpen, setClose } from "../slices/ToastSlice";

export const useToastStore = () => {
    const dispatch = useDispatch();
    const {
        isOpen,
        message
    } = useSelector(store => store?.Toast);

    const setOpenToastStore = (data) => {
        dispatch(setOpen(data));
    }

    const setCloseToastStore = () => {
        dispatch(setClose());
    }

    return {
        setOpenToastStore,
        setCloseToastStore,
        isOpen,
        message
    }
}