import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/login/Login';
import MiddlewareAuth from './components/middleware/MiddlewareAuth';
import NurcesPage from './pages/nurce/Index';
import Loading from './components/main/Loading';
import ShowNurce from './pages/nurce/Show';
import Toast from './components/main/Toast';
import PendingIncome from './pages/nurce/PendingIncome';
import ShowPendingIncome from './pages/nurce/ShowPendingIncome';

const App = () => {
  return (
    <>
    <Toast/>
    <Loading/>
      <Router>
        <Routes>
          <Route
            path='/login'
            element={
              <LoginPage/>
            }
          />
          <Route
            path='/'
            element={
              <MiddlewareAuth>
                <h1>home</h1>
              </MiddlewareAuth>
            }
          />
          <Route
            path='/enfermeros'
            element={
              <MiddlewareAuth>
                <NurcesPage/>
              </MiddlewareAuth>
            }
          />
          <Route
            path='/enfermeros/:id'
            element={
              <MiddlewareAuth>
                <ShowNurce/>
              </MiddlewareAuth>
            }
          />
          <Route
            path='/enfermeros/registros-pendientes'
            element={
              <MiddlewareAuth>
                <PendingIncome/>
              </MiddlewareAuth>
            }
          />
          <Route
            path='/enfermeros/registros-pendientes/:id'
            element={
              <MiddlewareAuth>
                <ShowPendingIncome/>
              </MiddlewareAuth>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
