import React from "react";
import { Navigate } from "react-router-dom";
import Menu from "../main/Menu";
import { useAuthStore } from "../../store/hooks/useAuthStore";
import { axiosClient } from "../../helpers/axios";

const MiddlewareAuth = ({ children }) => {
    const {user, token, resetAuthStore} = useAuthStore()
    axiosClient.interceptors.request.use(
        (config) => {
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
        },
        (error) => {
            if(error.respose.satus == 401){
                resetAuthStore()
            }
            return Promise.reject(error)
        }
      );
    
    if (user) {
        return (
            <div className="flex flex-row max-h-screen">
                <Menu />
                <div className="w-full h-screen overflow-auto">
                {children}
                </div>
            </div>
        )
    } else {
        return <Navigate to="/login"/>
    }

    /*if(children.type.name == 'Login'){
		return loggedIn ? children : <Navigate to="/"/>;
	}else{
		return loggedIn ? children : <Navigate to="/login"/>
	}*/

}

export default MiddlewareAuth