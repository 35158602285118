import React, { useEffect } from 'react'
import { useNurceStore } from '../../../store/hooks/useNurceStore';
import TablePaginate from '../../main/TablePaginate';
import { Link } from 'react-router-dom';

const TableNurce = () => {
    const headersTable = [
        { name: '', class: '' },
        { name: 'Nombre', class: '' },
        { name: 'Especialidad', class: '' },
        { name: 'Nivel', class: '' },
        { name: 'Disponibilidad', class: 'justify-between' },
        { name: 'Servicios', class: 'justify-between text-center' },
        { name: 'Calificación', class: 'justify-between text-center' },
        { name: 'Acciones', class: 'justify-between text-center' }
    ];

    const { getItems, items, paginate } = useNurceStore()

    useEffect(() => {
        getItems()
    }, [])

    const handleOnChangePaginate = (newPaginate) => {
        getItems(newPaginate)
    }

    return (
        <div className="flex flex-col w-full">
            <div className="flex flex-row w-full bg-o2o-gray-primary">
                {headersTable.map((header, index) => (
                    <p className={`flex flex-1 font-medium ${header.class}`} key={index}>
                        {header.name}
                    </p>
                ))}
            </div>
            {items?.map((item, index) => (
                <div className="flex flex-row w-full  items-center hover:bg-o2o-gray-secondary" key={index}>
                    <div className="flex flex-1 justify-center my-1 h-10">
                        <img src='https://static.vecteezy.com/system/resources/previews/001/886/209/non_2x/doctor-medical-cartoon-design-vector.jpg'
                            alt="nurce img"
                            className='object-fill rounded-full'
                        />
                    </div>
                    <div className='flex flex-1'>
                        {item?.full_name}
                    </div>
                    <div className="flex flex-1">
                        {item?.specialty?.name || 'Pediátrica'}
                    </div>
                    <div className="flex flex-1">
                        {item?.level?.name || ''}
                    </div>
                    <div className="flex flex-1">
                        {item?.availability?.name || 'Nocturno - 12 hrs'}
                    </div>
                    <div className="flex flex-1">
                        {item.availability || '7'}
                    </div>
                    <div className="flex flex-1">
                        {item.ranking || ''}
                    </div>
                    <div className="flex flex-1">
                        <Link to={`/enfermeros/${item.id}`} className='bg-black hover:bg-slate-600 text-white px-1 rounded'>
                            Ver usuario
                        </Link>
                    </div>
                </div>
            ))}
            <TablePaginate paginate={paginate} handlePaginate={(newPaginate) => handleOnChangePaginate(newPaginate)} />
        </div>
    )
}

export default TableNurce