import React from 'react'

const CustomSearchInput = ({ value, handleChange, handleOnBlur}) => {
    return (
        <div className="flex flex-row items-center w-full border border-black rounded focus:border-2">
            <div className="w-4 pt-1 mr-2">
                <span className="material-symbols-outlined">
                    search
                </span>
            </div>
            <div className="w-full">
                <input
                type="text"
                placeholder='Buscar'
                name="search"
                value={value}
                onChange={handleChange}
                className="w-full border-none checked:border-none focus:border-none"
                onBlur={handleOnBlur}
                />
            </div>
        </div>
    )
}

export default CustomSearchInput