import { useState } from "react";
import GenericCard from "../../components/cards/GenericCard";
import CustomSearchInput from "../../components/form/CustomSearchInput";
import CustomSelect from "../../components/form/CustomSelect";
import CustomButton from "../../components/form/CustomButton";
import TableNurce from "../../components/dashboard/nurces/TableNurce";
import FiltersModal from "../../components/dashboard/nurces/FiltersModal";
import { Link } from "react-router-dom";

export default function NurcesPage() {
  const [filters, setFilters] = useState({ id: 1, search: '', sort: 'A-Z' });
  const [openFilterModal, setFilterModal] = useState(false);
  const sortList = [
    { id: 1, name: 'A-Z', value: 'asc' },
    { id: 2, name: 'Z-A', value: 'desc' },
  ]

  const handleChange = ({ name, value }) => {
    setFilters({ ...filters, [name]: value });
  }

  return (
    <div className="flex flex-col w-full p-4">
      <div className="flex flex-row">
        <p className="font-bold text-lg">Enfermeros</p>
      </div>
      <div className="flex flex-row justify-between w-full">
        <div className="flex flex-row w-1/2">
          <div className="w-1/3">
            <GenericCard name="Total de usuarios" text="478" />
          </div>
          <div className="w-1/3 mx-2">
            <GenericCard name="Usuarios activos" text="218" />
          </div>
          <div className="w-1/3 mx-2">
            <GenericCard name="Nuevos registros" text="163" />
          </div>
        </div>
        <div className="flex flex-col justify-between w-1/2">
          <div className="flex flex-row w-full h-10 space-x-2">
            <Link to="/enfermeros/registros-pendientes" className={`flex justify-center w-1/2 items-center rounded bg-o2o-gray-secondary hover:bg-o2o-gray-primary`}>
              Registros pendientes
            </Link>
            <button className={`w-1/2 rounded bg-o2o-gray-secondary hover:bg-o2o-gray-primary`}>
              Cuentas bloqueados
            </button>
          </div>
          <div className="flex flex-row">
            <CustomSearchInput
              value={filters.search}
              handleChange={({ target }) => handleChange(target)} />
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full rounded shadow-xl border mt-4">
        <div className="flex flex-row justify-between p-4">
          <div className="flex flex-row w-3/4 items-center space-x-2">
            <p className="">Ordenar por</p>
            <div className="w-40">
              <CustomSelect name="sort" value={filters.sort} options={sortList} handleOnChange={() => console.log('error')} />
            </div>
            <div className="w-40">
              <CustomSelect name="sort" value={filters.sort} options={sortList} handleOnChange={() => console.log('error')}/>
            </div>
            <div className="w-40">
              <CustomSelect name="sort" value={filters.sort} options={sortList} handleOnChange={() => console.log('error')}/>
            </div>
          </div>
          <div className="flex flex-row w-1/4 items-center space-x-2">
            <p>Filtros</p>
            <CustomButton text="Personaliza tu búsqueda" handleOnClick={() => setFilterModal(true)} />
          </div>
        </div>
        <TableNurce /> 
        <FiltersModal isOpen={openFilterModal} close={() => setFilterModal(false)}/>
      </div>
    </div>
  );
}
