import { useDispatch, useSelector } from "react-redux";
import { setItems, setPaginate, setItem, setReasons } from "../slices/NurceSlice";
import { axiosClient } from "../../helpers/axios";
import { useLoadingStore } from "./useLoadingStore";
import { useToastStore } from "./useToastStore";

export const useNurceStore = () => {
    const { setOpenStore, setCloseStore } = useLoadingStore();
    const {setOpenToastStore} = useToastStore();
    const dispatch = useDispatch();
    const {
        items,
        paginate,
        item,
        reasons
    } = useSelector(store => store?.Nurce);

    const setItemsStore = (data) => {
        dispatch(setItems(data));
    }

    const setPaginateStore = (data) => {
        dispatch(setPaginate(data));
    }

    const setItemStore = (data) => {
        dispatch(setItem(data));
    }

    const setReasonsStore = (data) => {
        dispatch(setReasons(data));
    }

    const getItems = async (form = false) => {
        setOpenStore()
        const params = form ? {params: {...form, page: form.current_page}} : {} 
        await axiosClient.get('/nurces', params).then(response => {
            const {data, current_page, last_page, per_page} = response.data;
            setItemsStore(data)
            setPaginateStore({
                current_page,
                last_page,
                per_page
            })
        })
        .finally(() => setCloseStore())
    }

    const getItem = async (id) => {
        setOpenStore()
        await axiosClient.get(`/nurces/${id}`).then(response => {
            setItemStore(response?.data || null)
        })
        .finally(() => setCloseStore())
    }

    const getReasons = async () => {
        setOpenStore()
        await axiosClient.get(`/nurces/reasonsList`).then(response => {
            setReasonsStore(response?.data || [])
        })
        .finally(() => setCloseStore())
    }

    const putBlockAccount = async (id, form) => {
        setOpenStore()
        await axiosClient.put(`/nurces/${id}/blockAccount`, form).then(response => {
            setItemStore(response?.data || null)
            setOpenToastStore("Usuario enfermero bloqueado con éxito.")
        })
        .catch(errors => setOpenToastStore(errors?.response?.data))
        .finally(() => setCloseStore())
    }

    return {
        getItems,
        setPaginateStore,
        getItem,
        getReasons,
        putBlockAccount,
        items,
        paginate,
        item,
        reasons
    }
}