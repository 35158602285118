import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import CustomIcon from '../../components/main/CustomIcon';
import LevelsModal from '../../components/dashboard/nurces/LevelsModal';
import MessageDeclainRequestModal from '../../components/dashboard/nurces/MessageDeclainRequestModal';
import { useNurcePendingIncomeStore } from '../../store/hooks/useNurcePendingIncomeStore';

const ShowPendingIncome = () => {
    const { id } = useParams();
    const { item, getItem } = useNurcePendingIncomeStore();
    const [openModal, setOpenModal] = useState({acceptAccount: false, declainRequest: false});
    useEffect(() => {
        getItem(id)
    }, [])

    return (
        <>
            <div className='flex flex-col p-4'>
                <div className='flex flex-row items-center '>
                    <Link to="/enfermeros/registros-pendientes" className='inline-flex hover:underline'>
                        <CustomIcon icon="arrow_back_ios" />
                        <p className='font-bold text-lg'>Ingresos pendientes</p>
                    </Link>
                </div>
                <div className='flex flex-col space-y-8'>
                    <div className="flex flex-row w-full justify-between">
                        <div className='flex w-1/6'>
                            <img src='https://static.vecteezy.com/system/resources/previews/001/886/209/non_2x/doctor-medical-cartoon-design-vector.jpg'
                                alt="nurce img"
                                className='object-fill h-24 rounded-full mx-auto'
                            />
                        </div>
                        <div className='flex flex-col w-5/6'>
                            <div className='flex flex-row justify-between'>
                                <div className='flex flex-col flex-1'>
                                    <p className='font-medium text-lg'>{item?.name}</p>
                                    <p className='text-sm'>Nombre</p>
                                </div>
                                <div className='flex flex-col flex-1'>
                                    <p className='font-medium text-lg'>{item?.last_name}</p>
                                    <p className='text-sm'>Apellidos</p>
                                </div>
                                <div className='flex flex-col flex-1'>
                                    <p className='font-medium text-lg'>{item?.phone}</p>
                                    <p className='text-sm'>Móvil</p>
                                </div>
                                <div className='flex flex-col flex-1 space-y-1'>
                                    <button className='bg-black hover:opacity-90 text-white px-1 rounded' onClick={() => setOpenModal({...openModal, acceptAccount: true})}>
                                        Aceptar solicitud
                                    </button>
                                    <button className='bg-[#696969] hover:opacity-90 text-white px-1 rounded' onClick={() => setOpenModal({...openModal, declainRequest: true})}>
                                        Declinar solicitud
                                    </button>
                                    <button className='bg-[#8FA147] hover:opacity-90 text-white px-1 rounded' onClick={() => setOpenModal({...openModal, declainRequest: true})}>
                                        Agregar notas
                                    </button>
                                </div>
                            </div>
                            <div className='flex flex-row justify-between'>
                                <div className='flex flex-col flex-1'>
                                    <p className='font-medium text-lg'>{item?.curp}</p>
                                    <p className='text-sm'>CURP</p>
                                </div>
                                <div className='flex flex-col flex-1'>
                                    <p className='font-medium text-lg'>{item?.marital_status?.name}</p>
                                    <p className='text-sm'>Estado civil</p>
                                </div>
                                <div className='flex flex-col flex-1'>
                                    <p className='font-medium text-lg'>{item?.years}</p>
                                    <p className='text-sm'>Edad</p>
                                </div>
                                <div className='flex flex-col flex-1'>
                                    <p className='font-medium text-lg'>{item?.email}</p>
                                    <p className='text-sm'>Mail</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className='flex flex-row items-center w-full space-x-2'>
                            <p className='font-bold text-xs text-nowrap'>Dirección</p>
                            <hr className='w-full px-2' />
                        </div>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.name}</p>
                                <p className='text-sm'>Calle</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.last_name}</p>
                                <p className='text-sm'>Número exterior</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.phone}</p>
                                <p className='text-sm'>Número interior</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.email}</p>
                                <p className='text-sm'>Colonia</p>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.curp}</p>
                                <p className='text-sm'>Código postal</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.marital_status?.name}</p>
                                <p className='text-sm'>Municipio</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.years}</p>
                                <p className='text-sm'>Estado</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{
                                    new Date(Date.parse(item?.created_at))?.toLocaleDateString('es-MX', {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                    })}</p>
                                <p className='text-sm'>País</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className='flex flex-row items-center w-full space-x-2'>
                            <p className='font-bold text-xs text-nowrap'>Formación profesional</p>
                            <hr className='w-full px-2' />
                        </div>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>Lic. en Enfermería</p>
                                <p className='text-sm'>Estudios</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>EEA</p>
                                <p className='text-sm'>Casa de estudios</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>12345678</p>
                                <p className='text-sm'>cédula profesional</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>2017 - 2021</p>
                                <p className='text-sm'>Periodo</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className='flex flex-row items-center w-full space-x-2'>
                            <p className='font-bold text-xs text-nowrap'>Experiencia laboral</p>
                            <hr className='w-full px-2' />
                        </div>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>2022 - hoy</p>
                                <p className='text-sm'>Periodo</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>Hospital Ángeles</p>
                                <p className='text-sm'>Institución</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>Jefe de piso</p>
                                <p className='text-sm'>Cargo</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>Coordinación de equipo</p>
                                <p className='text-sm'>Actividades</p>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>2020 - 2022</p>
                                <p className='text-sm'>Periodo</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>Nuevo Sol de Puebla</p>
                                <p className='text-sm'>Institución</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>Enfermera</p>
                                <p className='text-sm'>Cargo</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>Cuidado de pacientes</p>
                                <p className='text-sm'>Actividades</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className='flex flex-row items-center w-full space-x-2'>
                            <p className='font-bold text-xs text-nowrap'>Formación academica</p>
                            <hr className='w-full px-2' />
                        </div>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>2021 - 2023</p>
                                <p className='text-sm'>Periodo</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>EEAngelópolis</p>
                                <p className='text-sm'>Institución</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>Posgrado en Salud Laboral y Prevención</p>
                                <p className='text-sm'>Estudios</p>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>2017 - 2021</p>
                                <p className='text-sm'>Periodo</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>EEAngelópolis</p>
                                <p className='text-sm'>Institución</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>Licenciatura en Enfermería</p>
                                <p className='text-sm'>Estudios</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className='flex flex-row items-center w-full space-x-2'>
                            <p className='font-bold text-xs text-nowrap'>Disponibilidad</p>
                            <hr className='w-full px-2' />
                        </div>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>Lunes, Miércoles y Viernes</p>
                                <p className='text-sm'>Días</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>Matutino</p>
                                <p className='text-sm'>Turno</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>8 hrs.</p>
                                <p className='text-sm'>Hasta</p>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>Martes y Jueves</p>
                                <p className='text-sm'>Días</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>Matutino</p>
                                <p className='text-sm'>Turno</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>12 hrs.</p>
                                <p className='text-sm'>Hasta</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className='flex flex-row items-center w-full space-x-2'>
                            <p className='font-bold text-xs text-nowrap'>Datos de facturación</p>
                            <hr className='w-full px-2' />
                        </div>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.name}</p>
                                <p className='text-sm'>RFC</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.last_name}</p>
                                <p className='text-sm'>Régimen fiscal</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.phone}</p>
                                <p className='text-sm'>CFDI</p>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.curp}</p>
                                <p className='text-sm'>Clave del certificado CSD (.key)</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.marital_status?.name}</p>
                                <p className='text-sm'>Certificado CSD (.cer)</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.years}</p>
                                <p className='text-sm'>Contraseña del certificado CSD</p>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.curp}</p>
                                <p className='text-sm'>Clave del certificado FIEL (.key)</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.marital_status?.name}</p>
                                <p className='text-sm'>Certificado de firma electrónica avanzada (FIEL) (.cer)</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.years}</p>
                                <p className='text-sm'>Contraseña del certificado de firma electrónica avanzada (FIEL)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LevelsModal isOpen={openModal.acceptAccount} close={() => setOpenModal({...openModal, acceptAccount: false})} />
            <MessageDeclainRequestModal isOpen={openModal.declainRequest} close={() => setOpenModal({...openModal, declainRequest: false})}/>
        </>
    )
}

export default ShowPendingIncome