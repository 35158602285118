import { AuthSlice } from "./slices/AuthSlice";
import {LoadingSlice} from "./slices/LoadingSlice"
import { NurcePendingIncomeSlice } from "./slices/NurcePendingIncomeSlice";
import {NurceSlice} from "./slices/NurceSlice"
import { ToastSlice } from "./slices/ToastSlice";

export const rootSlice = {
    Auth: AuthSlice.reducer,
    Toast: ToastSlice.reducer,
    Loading: LoadingSlice.reducer,
    Nurce: NurceSlice.reducer,
    NurcePendingIncome: NurcePendingIncomeSlice.reducer,
};