export default function CustomImput({ label, value, name, type, handleChange, error }) {
    const ErrorMensaje = () => {
        if(error){
            return (
                <span className="text-red-600">
                    {error}
                </span>
            )
        }
        return
    }
    return (
        <div className="flex flex-col w-full py-1">
            <label className="font-normal text-sm">{ label }</label>
            <input
            type={type}
            value={value}
            name={name}
            onChange={handleChange} 
            className={`border rounded-md h-12 ${error ? 'border-red-600': 'border-black'}`}/>
            <ErrorMensaje/>
        </div>
    );
}