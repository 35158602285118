import axios from 'axios'

export const axiosClient = axios.create({
    //baseURL: process.env.REACT_APP_BACKEND_URL + '/api/cms',
    // baseURL:'https://one2one-back.danthopdev.com'+ '/api/cms',
    baseURL:'https://one2one-backcms.danthopdev.com'+ '/api/cms',
    headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json; charset=utf-8'
    },
});

export const axiosCookie = axios.create({
    //baseURL: process.env.REACT_APP_BACKEND_URL,
    // baseURL:'https://one2one-back.danthopdev.com',
    baseURL:'http://one2one-backcms.danthopdev.com',
    headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json; charset=utf-8'
    }
});