import { createStore, applyMiddleware, compose } from "redux";
import { combineReducers } from "@reduxjs/toolkit";
import { loadState, saveState } from "./saveStore";
import {thunk} from 'redux-thunk';
import {rootSlice} from '../store/rootSlice'

const initialState = loadState();
const middleware = applyMiddleware(thunk)
const store = createStore(
  combineReducers({...rootSlice}),
  initialState,
  compose(middleware)
)

store.subscribe(function (){
  saveState(store.getState())
})

export default store;