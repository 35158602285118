import React from 'react'

const CustomSelect = ({value, name, options, handleOnChange}) => {
    return (
            <select name={name} defaultValue={value} value={value} className='border-2 border-dark w-full' onChange={({target}) => handleOnChange(target)}>
                {
                    options.map((item, index) => (
                        <option value={item.value} key={index} selected={item.value === value}>{item.name}</option>
                    ))
                }
            </select>
    )
}

export default CustomSelect