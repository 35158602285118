import { useDispatch, useSelector } from "react-redux";
import { setUser, setToken, resetAuth } from "../slices/AuthSlice";
import { axiosCookie, axiosClient } from '../../helpers/axios';
import { useLoadingStore } from "./useLoadingStore";

export const useAuthStore = () => {
    const dispatch = useDispatch();
    const {
        user,
        token
    } = useSelector(store => store?.Auth);

    const setUserStore = (data) => {
        dispatch(setUser(data));
    }

    const setTokenStore = (data) => {
        dispatch(setToken(data));
    }

    const resetAuthStore = () => {
        dispatch(resetAuth());
    }

    const { setOpenStore, setCloseStore } = useLoadingStore()

    const getLogin = async (form, callback) => {
        setOpenStore()
        await axiosCookie.get('/sanctum/csrf-cookie')
        .then(async () => {
            await axiosCookie.post('/api/login', {...form, deviceName: 'CMS'}).then(response => {
                const {user, authorization} = response.data
                setUserStore(user)
                setTokenStore(authorization)
                callback(false)
            })
            .catch(error => {
                callback(error?.response)
            })
            .finally(() => setCloseStore())
        });
    }

    const logout = async () => {
        setOpenStore()
        await axiosCookie.post('/api/logout')
            .then(() => {
                resetAuthStore()
            })
            .catch(error => {
                console.log(error)
                resetAuthStore()
            })
            .finally(() => setCloseStore())
    }

    return {
        /* Funciones */
        setUserStore,
        setTokenStore,
        getLogin,
        resetAuthStore,
        logout,
        /* Parametros */
        user,
        token
    }
}