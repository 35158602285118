import React from 'react'
import Modal from '../../modal/Modal'

const FiltersModal = ({isOpen, close}) => {

    return (
        <Modal open={isOpen} close={() => close()}>
            <div className='flex flex-col'>
                <p className='text-xl font-semibold text-center'>Filtros por agregar</p>
                <br />
            </div>
        </Modal>
    )
}

export default FiltersModal
