import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    items: [],
    paginate: {
        search: '',
        current_page: 1, 
        last_page: 1,
        per_page: 10
    },
    item: null,
    levels: [],
    reasons: []
}

export const NurcePendingIncomeSlice = createSlice({
    name: "nurcePendingIncome",
    initialState,
    reducers: {
        setItems: (state, {payload}) => {
            state.items = payload;
        },
        setPaginate: (state, {payload}) => {
            state.paginate = payload;
        },
        setItem: (state, {payload}) => {
            state.item = payload;
        },
        setLevels: (state, {payload}) => {
            state.levels = payload;
        },
        setReasons: (state, {payload}) => {
            state.reasons = payload;
        },
    }
});

export const {
    setItems,
    setPaginate,
    setItem,
    setReasons,
    setLevels
} = NurcePendingIncomeSlice.actions;