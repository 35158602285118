import React, { useEffect, useState } from 'react'
import { useNurcePendingIncomeStore } from '../../../store/hooks/useNurcePendingIncomeStore'
import ModalSmall from '../../modal/ModalSmall';
import { useNavigate  } from 'react-router-dom';
import { useNurceStore } from '../../../store/hooks/useNurceStore';

const LevelsModal = ({ isOpen, close }) => {
    const { getLevels, levels, putAcceptAccount } = useNurcePendingIncomeStore();
    const { item } = useNurceStore();
    const [form, setForm] = useState({
        level_id: ''
    });
    useEffect(() => {
        getLevels()
    }, []);
    let navigate = useNavigate();

    const handleChange = ({ name, value }) => {
        setForm({
            ...form,
            [name]: value
        });
    }

    const handleOnClose = () => {
        setForm({
            ...form,
            level_id: ''
        });
        close()
    }

    const handleOnClick = () => {
        putAcceptAccount(item.id, form)
        handleOnClose()
        return navigate("/enfermeros/registros-pendientes");
    }

    return (
        <ModalSmall open={isOpen} close={() => handleOnClose()}>
            <div className='flex flex-col'>
                <div className='flex flex-col'>
                    {
                        levels?.map((level, index) => (
                            <div className='flex flex-row font-normal text-base' key={index}>
                                <p>
                                    <input
                                        type="checkbox"
                                        name='level'
                                        value={level.id}
                                        defaultChecked={form.level_id == level.id}
                                        checked={form.level_id == level.id}
                                        onClick={({ target }) => handleChange({ name: 'level_id', value: level.id })}
                                    />{level.name}
                                </p>
                            </div>
                        ))
                    }
                </div>
                <div className='flex flex-row space-x-2 my-2'>
                    <button className='w-1/2 rounded bg-o2o-gray-secondary hover:bg-o2o-gray-primary' onClick={() => handleOnClose()}>
                        Cancelar
                    </button>
                    <button className='w-1/2 rounded bg-black hover:bg-gray-800 text-white' onClick={() => handleOnClick()}>
                        Aceptar
                    </button>
                </div>
            </div>
        </ModalSmall>
    )
}

export default LevelsModal