import React from 'react'
import Modal from '../modal/Modal'
import CustomButtonConfirm from '../form/CustomButtonConfirm'
import { useToastStore } from '../../store/hooks/useToastStore'

const Toast = () => {
    const {isOpen, message, setCloseToastStore} = useToastStore();
    return (
        <Modal open={isOpen} close={() => setCloseToastStore()}>
            <div className='flex flex-col'>
                <p className='text-xl font-semibold text-center'>{message}</p>
                <br />
                <CustomButtonConfirm text="Aceptar" handleOnClick={() => setCloseToastStore()} />
            </div>
        </Modal>
    )
}

export default Toast