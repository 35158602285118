import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    token: null,
    loading: false
}

export const AuthSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUser: (state, { payload }) => {
            state.user = payload;
        },
        setToken: (state, { payload }) => {
            state.token = payload;
        },
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        resetAuth: (state) => {
            state.user = null;
            state.token = null;
        }
    }
});

export const {
    setUser,
    setToken,
    setLoading,
    resetAuth
} = AuthSlice.actions;