import React from 'react'
import CustomIcon from './CustomIcon';

const TablePaginate = ({ paginate, handlePaginate }) => {
  const perPage = [10, 25, 50, 100];

  const handleChangePaginate = (name, value) => {
    let newPaginate = {...paginate}
    if(name === 'per_page'){
      newPaginate = {
        per_page: value,
        current_page: 1
      }
    }else{
      newPaginate = { ...paginate, [name]: value }
    }
    handlePaginate(newPaginate)
  }

  const FirstElement = () => {
    if (paginate.current_page - 1 > 0) {
      return (
        <p className='mx-auto rounded w-6 h-6 bg-o2o-gray-secondary text-center cursor-pointer' onClick={() => handleChangePaginate('current_page', paginate.current_page - 1)}>{paginate.current_page - 1}</p>
      )
    }
    return
  }

  const MainElement = () => {
    if (paginate.current_page > 0) {
      return (
        <p className='mx-auto rounded w-6 h-6 bg-o2o-gray-primary text-center cursor-pointer' onClick={() => handleChangePaginate('current_page', paginate.current_page)}>
          {paginate.current_page}
        </p>
      )
    }
    return
  }

  const LastElement = () => {
    if (paginate.current_page + 1 <= paginate.last_page) {
      return (
        <p className='mx-auto rounded w-6 h-6 bg-o2o-gray-secondary text-center cursor-pointer' onClick={() => handleChangePaginate('current_page', paginate.current_page + 1)}>
          {paginate.current_page + 1}
        </p>
      )
    }
    return
  }

  const PrevButton = () => {
    if (paginate.current_page - 1 > 0) {
      return (
        <div className='mx-auto rounded w-6 h-6 bg-o2o-gray-secondary cursor-pointer' onClick={() => handleChangePaginate('current_page', paginate.current_page - 1)}>
          <CustomIcon icon="arrow_back_ios" className='text-xs' />
        </div>
      )
    } else {
      return (
        <div className='mx-auto rounded w-6 h-6 bg-o2o-gray-secondary cursor-not-allowed'>
          <CustomIcon icon="arrow_back_ios" className='text-xs' />
        </div>
      )
    }
  }

  const NextButton = () => {
    if (paginate.current_page + 1 < paginate.last_page) {
      return (
        <div className='mx-auto rounded w-6 h-6 bg-o2o-gray-secondary cursor-pointer' onClick={() => handleChangePaginate('current_page', paginate.current_page + 1)}>
          <CustomIcon icon="arrow_forward_ios" />
        </div>
      )
    } else {
      return (
        <div className='mx-auto rounded w-6 h-6 bg-o2o-gray-secondary cursor-not-allowed'>
          <CustomIcon icon="arrow_forward_ios" />
        </div>
      )
    }
  }

  return (
    <div className="flex flex-row justify-between items-center p-4 border-t">
      <div className='flex flex-row space-x-1'>
        <p>Mostrar</p>
        <select value={paginate.per_page} className='rounded border' onChange={({target:{value}}) => handleChangePaginate('per_page', value)}>
          {
            perPage.map((element, index) => (
              <option value={element} selected={element === paginate.per_page} key={index}>{element}</option>
            ))
          }
        </select>
        <p>por pagina</p>
      </div>
      <div className='flex flex-row justify-end space-x-2'>
        <PrevButton />
        <FirstElement />
        <MainElement />
        <LastElement />
        <NextButton />
      </div>
    </div>
  )
}

export default TablePaginate;