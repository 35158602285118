import React from 'react'

const FirstTab = ({ item }) => {
    return (
        <div className='tab-content tab-content-active space-y-8' id="item-content-1">
            <div className="flex flex-row w-full justify-between">
                <div className='flex w-1/6'>
                    <img src='https://static.vecteezy.com/system/resources/previews/001/886/209/non_2x/doctor-medical-cartoon-design-vector.jpg'
                        alt="nurce img"
                        className='object-fill h-24 rounded-full mx-auto'
                    />
                </div>
                <div className='flex flex-col w-5/6'>
                    <div className='flex flex-row justify-between'>
                        <div className='flex flex-col flex-1'>
                            <p className='font-medium text-lg'>{item?.name}</p>
                            <p className='text-sm'>Nombre</p>
                        </div>
                        <div className='flex flex-col flex-1'>
                            <p className='font-medium text-lg'>{item?.last_name}</p>
                            <p className='text-sm'>Apellidos</p>
                        </div>
                        <div className='flex flex-col flex-1'>
                            <p className='font-medium text-lg'>{item?.phone}</p>
                            <p className='text-sm'>Móvil</p>
                        </div>
                        <div className='flex flex-col flex-1'>
                            <p className='font-medium text-lg'>{item?.email}</p>
                            <p className='text-sm'>Mail</p>
                        </div>
                    </div>
                    <div className='flex flex-row justify-between'>
                        <div className='flex flex-col flex-1'>
                            <p className='font-medium text-lg'>{item?.curp}</p>
                            <p className='text-sm'>CURP</p>
                        </div>
                        <div className='flex flex-col flex-1'>
                            <p className='font-medium text-lg'>{item?.marital_status?.name}</p>
                            <p className='text-sm'>Estado civil</p>
                        </div>
                        <div className='flex flex-col flex-1'>
                            <p className='font-medium text-lg'>{item?.years}</p>
                            <p className='text-sm'>Edad</p>
                        </div>
                        <div className='flex flex-col flex-1'>
                            <p className='font-medium text-lg'>{
                                new Date(Date.parse(item?.created_at))?.toLocaleDateString('es-MX', {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                })}</p>
                            <p className='text-sm'>Registrado desde</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col">
                <div className='flex flex-row items-center w-full space-x-2'>
                    <p className='font-bold text-xs text-nowrap'>Dirección de facturación</p>
                    <hr className='w-full px-2' />
                </div>
                <div className='flex flex-row justify-between'>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.name}</p>
                        <p className='text-sm'>Calle</p>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.last_name}</p>
                        <p className='text-sm'>Número exterior</p>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.phone}</p>
                        <p className='text-sm'>Número interior</p>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.email}</p>
                        <p className='text-sm'>Colonia</p>
                    </div>
                </div>
                <div className='flex flex-row justify-between'>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.curp}</p>
                        <p className='text-sm'>Código postal</p>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.marital_status?.name}</p>
                        <p className='text-sm'>Municipio</p>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.years}</p>
                        <p className='text-sm'>Estado</p>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{
                            new Date(Date.parse(item?.created_at))?.toLocaleDateString('es-MX', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                            })}</p>
                        <p className='text-sm'>País</p>
                    </div>
                </div>
            </div>
            <div className="flex flex-col">
                <div className='flex flex-row items-center w-full space-x-2'>
                    <p className='font-bold text-xs text-nowrap'>Datos de facturación</p>
                    <hr className='w-full px-2' />
                </div>
                <div className='flex flex-row justify-between'>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.name}</p>
                        <p className='text-sm'>RFC</p>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.last_name}</p>
                        <p className='text-sm'>Régimen fiscal</p>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.phone}</p>
                        <p className='text-sm'>CFDI</p>
                    </div>
                </div>
                <div className='flex flex-row justify-between'>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.curp}</p>
                        <p className='text-sm'>Clave del certificado CSD (.key)</p>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.marital_status?.name}</p>
                        <p className='text-sm'>Certificado CSD (.cer)</p>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.years}</p>
                        <p className='text-sm'>Contraseña del certificado CSD</p>
                    </div>
                </div>
                <div className='flex flex-row justify-between'>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.curp}</p>
                        <p className='text-sm'>Clave del certificado FIEL (.key)</p>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.marital_status?.name}</p>
                        <p className='text-sm'>Certificado de firma electrónica avanzada (FIEL) (.cer)</p>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.years}</p>
                        <p className='text-sm'>Contraseña del certificado de firma electrónica avanzada (FIEL)</p>
                    </div>
                </div>
            </div>
            <div className="flex flex-row space-x-2">
                <div className='flex flex-col w-1/2'>
                    <div className='flex flex-row items-center w-full space-x-2'>
                        <p className='font-bold text-xs text-nowrap'>Descripción personal</p>
                        <hr className='w-full px-2' />
                    </div>
                    <p className='font-normal text-lg'>{item?.personal_description}</p>
                </div>
                <div className='flex flex-col w-1/2'>
                    <div className='flex flex-row items-center w-full space-x-2'>
                        <p className='font-bold text-xs text-nowrap'>Información que lo hace único</p>
                        <hr className='w-full px-2' />
                    </div>
                    <p className='font-normal text-lg'>{item?.additional_information}</p>
                </div>
            </div>
            <div className="flex flex-col">
                <div className='flex flex-row items-center w-full space-x-2'>
                    <p className='font-bold text-xs text-nowrap'>Datos de facturación</p>
                    <hr className='w-full px-2' />
                </div>
                <div className='flex flex-row space-x-2'>
                    <span className='flex items-center bg-[#E5E5E5] px-4 rounded-full border border-black h-6'>arte</span>
                    <span className='flex items-center bg-[#E5E5E5] px-4 rounded-full border border-black h-6'>yoga</span>
                    <span className='flex items-center bg-[#E5E5E5] px-4 rounded-full border border-black h-6'>atletismo</span>
                </div>
            </div>
        </div>
    )
}

export default FirstTab