import React, { useEffect, useState } from 'react'
import Modal from '../../modal/Modal'
import { useNurceStore } from '../../../store/hooks/useNurceStore';

const MessageBloquedModal = ({ isOpen, close }) => {
    const { item, reasons, getReasons, putBlockAccount } = useNurceStore();
    const [form, setForm] = useState({
        description: '',
        reasons: []
    });
    const [errorsForm, setErrorsForm] = useState({
        description: '',
        reasons: []
    });
    const [confirmation, setConfirmation] = useState(false);


    useEffect(() => {
        getReasons()
    }, []);

    const handleChange = ({ name, value }) => {
        if (name === 'reasons') {
            let reasonArray = [...form.reasons];
            if (form?.reasons?.includes(value)) {
                reasonArray = reasonArray.filter(i => i !== value);
            } else {
                reasonArray = [...reasonArray, value];
            }
            setForm({
                ...form,
                reasons: reasonArray
            })
        } else {
            setForm({
                ...form,
                [name]: value
            })
        }

        setErrorsForm({
            ...errorsForm,
            [name]: false
        })
    }

    const handleOnCancel = () => {
        setForm({
            description: '',
            reasons: []
        });
        setErrorsForm({
            description: '',
            reasons: []
        })
        setConfirmation(false)
        close()
    }

    const handleOnBlockAccount = () => {
        putBlockAccount(item.id, form);
        handleOnCancel();
    }

    return (
        <>
            <Modal open={isOpen} close={() => close()}>
                <div className='flex flex-col'>
                    <p className='text-xl font-semibold text-justify'>
                        ¿Estás seguro de bloquear/suspender la cuenta de {item?.full_name}?
                    </p>
                    <p className='text-base my-2'>Selecciona la razón o razones: </p>
                    <div className='flex flex-col'>
                        {
                            reasons?.map((reason, index) => (
                                <div className='flex flex-row font-normal text-sm' key={index}>
                                    <p>
                                        <input
                                        type="checkbox"
                                        name='reasons'
                                        value={reason.id}
                                        defaultChecked={form?.reasons?.includes(reason.id)}
                                        checked={form?.reasons?.includes(reason.id)}
                                        onClick={({ target }) => handleChange({name: 'reasons', value: reason.id})}
                                        />{reason.name}</p>
                                </div>
                            ))
                        }
                    </div>
                    <p className='text-base my-2'>Descripción del problema:</p>
                    <div className='flex w-full'>
                        <textarea
                            name="description" rows="5"
                            className='resize-none border border-black rounded w-full'
                            value={form.description}
                            onChange={({ target }) => handleChange(target)}
                        >
                            {form.description}
                        </textarea>
                    </div>
                    <div className='flex flex-row space-x-2 my-2'>
                        <button className='w-1/2 rounded bg-o2o-gray-secondary hover:bg-o2o-gray-primary' onClick={() => handleOnCancel()}>
                            Cancelar
                        </button>
                        <button className='w-1/2 rounded bg-black hover:bg-gray-800 text-white' onClick={() => setConfirmation(true)}>
                            Aceptar
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal open={confirmation} close={() => close()}>
                <div className='flex flex-col'>
                    <p className='text-xl font-semibold text-justify'>
                    ¿Estás seguro de continuar con la suspensión de la cuenta de {item?.full_name}?
                    </p>
                    <div className='flex flex-row space-x-2 my-2'>
                        <button className='w-1/2 rounded bg-o2o-gray-secondary hover:bg-o2o-gray-primary' onClick={() => handleOnCancel()}>
                            Cancelar
                        </button>
                        <button className='w-1/2 rounded bg-black hover:bg-gray-800 text-white' onClick={() => handleOnBlockAccount()}>
                            Aceptar
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default MessageBloquedModal
