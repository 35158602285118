import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { useNurceStore } from '../../store/hooks/useNurceStore';
import CustomIcon from '../../components/main/CustomIcon';
import MessageBloquedModal from '../../components/dashboard/nurces/MessageBloquedModal';
import TabsNurce from '../../components/dashboard/nurces/tab/TabsNurce';

const ShowNurce = () => {
  const { id } = useParams();
  const { item, getItem } = useNurceStore();
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    getItem(id)
  }, [])

  const ButtonBloquetAccount = () => {
    if (item?.bloqued_at === null) {
      return (
        <button className='bg-black hover:bg-slate-600 text-white px-1 rounded' onClick={() => setOpenModal(true)}>
          Bloquear cuenta
        </button>
      );
    }
    return
  }

  return (
    <>
      <div className='flex flex-col p-4'>
        <div className='flex flex-row items-center '>
          <Link to="/enfermeros" className='inline-flex hover:underline'>
            <CustomIcon icon="arrow_back_ios" />
            <p className='font-bold text-lg'>{item?.full_name}</p>
          </Link>
        </div>

        <div className='flex flex-row justify-end'>
          <ButtonBloquetAccount />
        </div>
        <TabsNurce item={item}/>
      </div>
      <MessageBloquedModal isOpen={openModal} close={() => setOpenModal(false)} />
    </>
  )
}

export default ShowNurce