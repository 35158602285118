import { useDispatch, useSelector } from "react-redux";
import { setOpen, setClose } from "../slices/LoadingSlice";

export const useLoadingStore = () => {
    const dispatch = useDispatch();
    const {
        isOpen,
    } = useSelector(store => store?.Loading);

    const setOpenStore = () => {
        dispatch(setOpen());
    }

    const setCloseStore = () => {
        dispatch(setClose());
    }

    return {
        setOpenStore,
        setCloseStore,
        isOpen
    }
}