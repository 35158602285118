import React, { useState } from 'react'
import CustomImput from '../../components/form/CustomInput';
import { Link, useNavigate } from 'react-router-dom';
import CustomButtonConfirm from '../../components/form/CustomButtonConfirm';
import { useAuthStore } from '../../store/hooks/useAuthStore';
import Modal from '../../components/modal/Modal';

const Login = () => {
    const navigate = useNavigate();
    const { getLogin } = useAuthStore()
    const [form, setForm] = useState({ email: '', password: '' });
    const [errorsForm, setErrorsForm] = useState({ email: false, password: false });
    const [openModal, setOpenModal] = useState(false);

    const handleChange = ({ name, value }) => {
        setForm({
            ...form,
            [name]: value
        })
        setErrorsForm({
            ...errorsForm,
            [name]: false
        })
    }

    const handleOnclick = async () => {
        getLogin(form, (error) => {
            if (error) {
                if (error?.status === 422) {
                    const { errors } = error.data
                    let newErrors = errorsForm
                    Object.keys(errors).forEach(function (key) {
                        newErrors = {
                            ...newErrors,
                            [key]: errors[key][0]
                        }
                    })
                    setErrorsForm(newErrors)
                    setOpenModal(true)
                }
            } else {
                navigate('/')
            }
        })
    }

    return (
        <div className="flex flex-row justify-center items-center w-screen h-screen select-none">
            <div className="hidden w-1/2 md:flex">
                <p className="text-center mx-auto">Imagen</p>
            </div>
            <div className="flex flex-col justify-between max-w-screen-sm w-full md:w-1/2 h-full p-4 md:p-0 md:h-3/4">
                <div className="flex flex-col font-bold text-5xl text-o2o-gray-title h-1/2">
                    <p>One2One</p>
                    <p>Nurses</p>
                </div>
                <div className="flex flex-col justify-end md:w-1/2 h-1/2">
                    <div className="flex w-full my-4">
                        <CustomImput
                            label="Correo electrónico"
                            type="email"
                            name="email"
                            error={errorsForm?.email}
                            value={form.email}
                            handleChange={({ target }) => handleChange(target)}
                        />
                    </div>
                    <div className="flex w-full my-4">
                        <CustomImput
                            label="Contraseña"
                            type="password"
                            name="password"
                            value={form.password}
                            error={errorsForm?.password}
                            handleChange={({ target }) => handleChange(target)}
                        />
                    </div>
                    <div className="flex w-full my-4">
                        <CustomButtonConfirm
                            text="Iniciar Sesión"
                            handleOnClick={() => handleOnclick()}
                        />
                    </div>
                    <div className="flex w-full my-4">
                        <Link
                            to="/"
                            className="hover:underline text-center mx-auto"
                        >
                            ¿Olvidaste tu contraseña?
                        </Link>
                    </div>
                </div>
            </div>
            <Modal open={openModal} close={() => setOpenModal(false)}>
                <div className='flex flex-col'>
                    <p className='text-xl font-semibold text-center'>Correo o contraseña incorrectos, ingresa nuevamente las credenciales.</p>
                    <br/>
                    <CustomButtonConfirm text="Aceptar" handleOnClick={() => setOpenModal(false)}/>
                </div>
            </Modal>
        </div>
    );
}

export default Login;
