export default function CustomButton({ text, handleOnClick }) {
    return (
        <div className="flex w-full py-1">
            <button
                type="button"
                className="bg-[#656565] hover:bg-[#EEEEEE] w-full font-normal text-lg text-white hover:text-black mx-auto h-7 rounded"
                onClick={handleOnClick}
            >
                {text}
            </button>
        </div>
    )
}