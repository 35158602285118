import React, { useEffect } from 'react'
import TablePaginate from '../../main/TablePaginate';
import { Link } from 'react-router-dom';
import { useNurcePendingIncomeStore } from '../../../store/hooks/useNurcePendingIncomeStore';

const TablePendingIncome = () => {
    const { getItems, items, paginate } = useNurcePendingIncomeStore()

    useEffect(() => {
        getItems()
    }, [])

    const handleOnChangePaginate = (newPaginate) => {
        getItems(newPaginate)
    }

    return (
        <div className="flex flex-col w-full">
            <hr />
            <div className='flex flex-row w-full flex-wrap p-4'>
                {items?.map((item, index) => (
                    <div className="flex w-1/5 items-center select-none" key={index}>
                        <div className='flex flex-col w-full bg-[#F6F6F6] m-2 p-2 rounded'>
                            <div className="flex flex-row w-full">
                                <div className='flex w-1/3'>
                                    <img src='https://static.vecteezy.com/system/resources/previews/001/886/209/non_2x/doctor-medical-cartoon-design-vector.jpg'
                                        alt="nurce img"
                                        className='object-fill h-12 rounded-full mx-auto'
                                    />
                                </div>
                                <div className='flex flex-col w-2/3'>
                                    <p className='font-bold text-base'>{item?.name}</p>
                                    <p className='text-xs'>Nombre</p>
                                </div>
                            </div>
                            <div className="flex flex-col w-full">
                                <p className='font-bold text-base'>{item?.last_name}</p>
                                <p className='text-xs'>Apellido</p>
                            </div>
                            <div className="flex flex-col w-full">
                                <p className='font-bold text-base uppercase'>{item?.curp}</p>
                                <p className='text-xs'>CURP</p>
                            </div>
                            <div className="flex flex-col w-full">
                                <p className='font-bold text-base uppercase'>Lic. en efermeria</p>
                                <p className='text-xs'>Estudios</p>
                            </div>
                            <div className="flex flex-col w-full">
                                <p className='font-bold text-base uppercase'>12345678</p>
                                <p className='text-xs'>Cédula profesional </p>
                            </div>
                            <div className="flex flex-col w-full pt-2">
                                <Link to={`/enfermeros/registros-pendientes/${item.id}`} className='bg-black hover:bg-slate-600 mx-2 text-white text-center px-1 rounded'>
                                    Ver solicitud
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <TablePaginate paginate={paginate} handlePaginate={(newPaginate) => handleOnChangePaginate(newPaginate)} />
        </div>
    )
}

export default TablePendingIncome