import React, { useState } from 'react'
import CustomIcon from '../../components/main/CustomIcon'
import { Link } from 'react-router-dom'
import GenericCard from '../../components/cards/GenericCard'
import CustomSelect from '../../components/form/CustomSelect'
import TablePendingIncome from '../../components/dashboard/nurces/TablePendingIncome'
import CustomSearchInput from '../../components/form/CustomSearchInput'
import { useNurcePendingIncomeStore } from '../../store/hooks/useNurcePendingIncomeStore'

const PendingIncome = () => {
    const { getItems, paginate } = useNurcePendingIncomeStore()
    const [filters, setFilters] = useState({ id: 1, search: '', sort: 'asc' });
    const sortList = [
        { id: 1, name: 'A-Z', value: 'asc' },
        { id: 2, name: 'Z-A', value: 'desc' },
    ];

    const handleChange = ({ name, value }) => {
        setFilters({ ...filters, [name]: value });
    }

    const handleOnSearch = () => {
        getItems({ ...paginate, search: filters.search, sort: filters.sort });
    }

    return (
        <div className='flex flex-col p-4'>
            <div className='flex flex-row items-center '>
                <Link to="/enfermeros" className='inline-flex hover:underline'>
                    <CustomIcon icon="arrow_back_ios" />
                    <p className='font-bold text-lg'>Ingresos pendientes </p>
                </Link>
            </div>
            <div className='flex flex-row'>
                <div className="w-40">
                    <GenericCard name="Nuevos registros" text="478" />
                </div>
                <div className="w-42 mx-2">
                    <GenericCard name="Registros pendientes" text="218" />
                </div>
            </div>
            <div className="flex flex-col w-full rounded shadow-xl border mt-4">
                <div className="flex flex-row justify-between p-4">
                    <div className="flex flex-row w-3/4 items-center space-x-2">
                        <p className="">Ordenar por</p>
                        <div className="w-40">
                            <CustomSelect name="sort" value={filters.sort} options={sortList} handleOnChange={(val) => { handleChange(val); handleOnSearch(); }} />
                        </div>
                        <div className="w-40">
                            <CustomSelect name="sort" value={filters.sort} options={sortList} />
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <CustomSearchInput
                            value={filters.search}
                            handleChange={({ target }) => handleChange(target)}
                            handleOnBlur={() => handleOnSearch()} />
                    </div>
                    
                </div> 
                <TablePendingIncome />
            </div>
        </div>
    )
}

export default PendingIncome